import FF from "../../components/forms/FormField.module.css";

import type { AdminHostUpdateBody } from "@warrenio/api-spec/spec.oats.gen";
import { useState, type ReactNode } from "react";
import { entries } from "remeda";
import C from "../../components/Badge.module.css";
import { Badge, type BadgeProps } from "../../components/Badge.tsx";
import { EditableButton } from "../../components/forms/EditableButton.tsx";
import { RadioGroupDiv } from "../../components/forms/RadioGroupDiv.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { cn } from "../../utils/classNames.ts";
import type { AdminHostWithType } from "./hostQuery.ts";
import { hostUpdateMutation } from "./hostQuery.ts";
import { ModalHeaderBlock } from "./ModalHeaderBlock.tsx";

type StatusType = "AVAILABLE" | "NOT ACCEPTING" | "MAINTENANCE" | "OFFLINE";

interface StatusTypeItem {
    label: ReactNode;
    color: keyof typeof C & BadgeProps["color"];
    description: ReactNode;
    flags: Pick<AdminHostUpdateBody, "is_accepting_workloads" | "is_maintenance" | "is_offline">;
}

export const statusTypes: Record<StatusType, StatusTypeItem> = {
    AVAILABLE: {
        label: "AVAILABLE",
        color: "success",
        description: "Host is considered a valid target for new and migrated VMs. Existing VMs will keep running",
        flags: {
            is_accepting_workloads: true,
            is_maintenance: false,
            is_offline: false,
        },
    },
    "NOT ACCEPTING": {
        label: "NOT ACCEPTING",
        color: "warning",
        description:
            "Host is not available for new VMs, but existing VMs will continue running. Admin will still be able to explicitly migrate VMs to that host",
        flags: {
            is_accepting_workloads: false,
            is_maintenance: false,
            is_offline: false,
        },
    },
    MAINTENANCE: {
        label: "MAINTENANCE",
        color: "error",
        description:
            "All VMs on this host will be automatically migrated to other hosts. Use this if the host is accessible and you want to make it empty",
        flags: {
            is_accepting_workloads: false,
            is_maintenance: true,
            is_offline: false,
        },
    },
    OFFLINE: {
        label: "OFFLINE",
        color: "text",
        description:
            "Host is considered to be unavailable, all VMs on this host will be automatically recreated on other hosts (it is like doing Force Stop and Start to all its VMs). Use only if you are sure that there are no running VMs on that host!",
        flags: {
            is_accepting_workloads: false,
            is_maintenance: false,
            is_offline: true,
        },
    },
};

interface StatusBadgeModalProps {
    item: AdminHostWithType;
    isDot?: boolean;
}

export function StatusBadge({ item, isDot }: StatusBadgeModalProps) {
    const status = statusTypes[getStatus(item)];

    return isDot ? (
        <div className={cn(C.BadgeDot, C.Dot, C[status.color])} />
    ) : (
        <Badge color={status.color}>{status.label}</Badge>
    );
}

export function StatusBadgeButton(props: StatusBadgeModalProps) {
    return (
        <WModal
            button={
                <EditableButton action={undefined}>
                    <StatusBadge {...props} />
                </EditableButton>
            }
        >
            <StatusBadgeModalContent {...props} />
        </WModal>
    );
}

function getStatus(item: AdminHostWithType): StatusType {
    if (item.is_offline) {
        return "OFFLINE";
    } else if (item.is_maintenance) {
        return "MAINTENANCE";
    } else if (item.is_accepting_workloads) {
        return "AVAILABLE";
    } else {
        return "NOT ACCEPTING";
    }
}

function StatusBadgeModalContent({ item }: StatusBadgeModalProps) {
    const defaultStatus = getStatus(item);
    const [status, setStatus] = useState<StatusType>(defaultStatus);
    const updateMutation = useStandardMutation(hostUpdateMutation);

    async function onSubmit() {
        const statuses = statusTypes[status].flags;
        await updateMutation.mutateAsync({
            body: {
                uuid: item.uuid,
                ...statuses,
            },
            location: item.location,
        });
    }

    return (
        <WModalContent title="Change Hypervisor Status" label="Change" modalAction={async () => await onSubmit()}>
            <ModalHeaderBlock item={item}>
                <StatusBadge item={item} />
            </ModalHeaderBlock>

            <div className="pt-3 pb-2">Change status*</div>
            <RadioGroupDiv>
                {entries(statusTypes).map(([key, type]) => {
                    const isSelected = status === key;

                    return (
                        <div
                            key={key}
                            onClick={() => setStatus(key)}
                            data-selected={isSelected ? true : undefined}
                            className={cn(FF.FormFieldIconRadio, FF.Size)}
                        >
                            <div className={cn(FF.Header, "py-1")}>
                                <Badge reverse={isSelected} noDot color={type.color}>
                                    {key}
                                </Badge>
                            </div>

                            <div className="p-2 font-size-small">{type.description}</div>
                        </div>
                    );
                })}

                <p className="color-muted font-size-small grid-col-span-2">
                    Changing the host flag can be a dangerous operation, read the flag description carefully before
                    submitting! Use this feature only if you know what you are doing.
                </p>
            </RadioGroupDiv>
        </WModalContent>
    );
}
